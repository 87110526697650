import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getCourseDetailInfo } from 'helpers';
import { getCurrentViewedCourseDetails, getAllCoursesGeneralData } from 'selectors';
import PDFHeader from 'components/pdf/header';
import PDFFooter from 'components/pdf/footer';
import FooterNote from 'components/footer/footerNote';
import InsightsLoader from 'components/loaders/InsightsLoader';
import SummaryStatsCourseDetail from 'pages/insights/containers/courseDetailContainer/components/summaryStatsCourseDetail';
import DownloadCourseDetail from 'pages/insights/containers/courseDetailContainer/components/downloadCourseDetail';
import CourseLearnersOverview from 'pages/insights/containers/courseDetailContainer/components/courseLearnersOverview';
import { courseAnalyticsActions } from 'pages/insights/containers/courseDetailContainer/actions';
import { coursesAnalyticsActions } from 'pages/insights/containers/coursesAnalyticsContainer/actions';
import { escapeHtml } from 'helpers';

const moment = require('moment');

const CourseDetail = (props) => {
  const { all_courses_stats, course_info, dispatch } = props;
  const { course_id } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);

  const defaultState = {
    filter_type: 'this_year',
    filter_start_date: moment().subtract(1, 'months'),
    filter_end_date: moment()
  };
  const [CourseDetailFilterType, setCourseDetailFilterType] = useState(defaultState.filter_type);
  const [CourseDetailFilterStartDate, setCourseDetailStartDate] = useState(defaultState.filter_start_date);
  const [CourseDetailFilterEndDate, setCourseDetailFilterEndDate] = useState(defaultState.filter_end_date);

  const handleFilterChange = (value) => {
    setCourseDetailFilterType(value);
  };

  const handleSetStartDate = (date) => {
    setCourseDetailStartDate(date);
  };

  const handleSetEndDate = (date) => {
    setCourseDetailFilterEndDate(date);
  };

  const handleRefresh = (e) => {
    e.preventDefault();
    dispatch(
      courseAnalyticsActions.fetchCourseDetail({
        course_id: course_id,
        filter_type: CourseDetailFilterType,
        filter_start_date: CourseDetailFilterStartDate,
        filter_end_date: CourseDetailFilterEndDate
      })
    );
  };

  const setDefaultFilter = (e) => {
    e.preventDefault();
    setCourseDetailFilterType(defaultState.filter_type);
    dispatch(
      courseAnalyticsActions.fetchCourseDetail({
        course_id: course_id,
        ...defaultState
      })
    );
  };

  useEffect(() => {
    dispatch(courseAnalyticsActions.fetchCourseEnrollmentsData({ course_id: course_id }, stopLoader));
    dispatch(courseAnalyticsActions.fetchCourseAllLearnersData({ course_id: course_id }));
    dispatch(
      courseAnalyticsActions.fetchCourseDetail({
        course_id: course_id,
        filter_type: CourseDetailFilterType,
        filter_start_date: CourseDetailFilterStartDate,
        filter_end_date: CourseDetailFilterEndDate
      })
    );
    dispatch(coursesAnalyticsActions.fetchAllCoursesGeneralData({ course_id: course_id }));
  }, [course_id, dispatch, CourseDetailFilterEndDate, CourseDetailFilterStartDate, CourseDetailFilterType]);

  const stopLoader = () => {
    setIsLoaded(true);
  };

  return (
    <div id="page_content" className="courses-analytics">
      {isLoaded && Object.hasOwn(all_courses_stats, 'results') ? (
        <Fragment>
          <PDFHeader />
          <div className="page-content-header">
            <h1>{course_info ? escapeHtml(course_info.course_name) : course_id}</h1>
            <DownloadCourseDetail {...props} course_id={course_id} />
          </div>
          <SummaryStatsCourseDetail
            {...props}
            course_id={course_id}
            course_detail={getCourseDetailInfo(all_courses_stats.results, course_id)}
            handleFilterChange={handleFilterChange}
            handleSetStartDate={handleSetStartDate}
            handleSetEndDate={handleSetEndDate}
            handleRefresh={handleRefresh}
            setDefaultFilter={setDefaultFilter}
            CourseDetailFilterType={CourseDetailFilterType}
          />
          <CourseLearnersOverview {...props} course_id={course_id} />
          <FooterNote />
          <PDFFooter />
        </Fragment>
      ) : (
        <InsightsLoader />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const {
    enrollments_count,
    course_info,
    enrollments_data,
    current_page,
    all_learners_info
  } = getCurrentViewedCourseDetails(state);
  const { all_courses_maus, all_courses_stats } = getAllCoursesGeneralData(state);

  return {
    course_info,
    all_courses_stats,
    all_learners_info,
    all_courses_maus,
    enrollments_count,
    enrollments_data,
    current_page
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(courseAnalyticsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetail);
