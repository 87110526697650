import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userSiteActions } from 'pages/dashboard/containers/sites/actions/userSites';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { userSettingsService } from 'services/settings';
import { setupOrganizationLocalStorage } from 'helpers';
import { settingsDispatches } from 'pages/settings/containers/settingsContainer/constants';
import { checkUserPanelAccess, setupCsrfToken } from 'helpers/dashboard';

const UserSite = (props) => {
  const { site, userSelectedSite, handleSetLoading, dispatch } = props;
  const history = useHistory();
  const selected = userSelectedSite.includes(site.slug);

  const handleNavigate = async (event) => {
    event.stopPropagation();
    handleSetLoading(true);
    localStorage.setItem('organization_url', site.panel_base_url);
    let organization = await userSettingsService.fetchOrganizations();
    const { show_user_guide, ...rest } = organization.data.results[0];
    organization.data.results[0] = rest;
    setupOrganizationLocalStorage(dispatch, organization, false);
    setupCsrfToken();
    await Promise.resolve(dispatch(settingsDispatches.fetchOrganizationSuccess(organization))).then(() => {
      dispatch(settingsDispatches.fetchUsernameSuccess(organization));
    });
    dispatch(userSiteActions.setUserCurrentSites({ data: site }));
    handleSetLoading(false);
    if (site.user_panel_role === 'panel_user') {
      history.push('settings/profile');
    } else {
      history.push('insights/courses');
    }
  };

  const handleSelectedSites = (event) => {
    if (checkUserPanelAccess(site.user_panel_role)) {
      dispatch(
        userSiteActions.UpdateSelectedSites({
          removedSelectedSite: userSelectedSite.includes(site.slug),
          data: site.slug
        })
      );
    }
  };

  return (
    <div className={`slide-card ${selected ? 'card-selected' : ''}`} onClick={handleSelectedSites}>
      <div className="card-text">
        <div className="site-name">
          {site.slug !== 'all' && checkUserPanelAccess(site.user_panel_role) && (
            <div className={`checkbox ${selected ? 'checked' : ''}`}>
              <FontAwesomeIcon icon={Icons.faCheck} className="fa-xs" />
            </div>
          )}
          <h5>{site.slug}</h5>
        </div>
        {site.slug !== 'all' && (
          <button className="btn btn-link" onClick={handleNavigate}>
            view site panel
          </button>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userSelectedSite: state.USER_SITES.selected_sites
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

const connectedUserSite = connect(mapStateToProps, mapDispatchToProps)(UserSite);
export default connectedUserSite;
