import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import Slider from 'react-slick';
import React, { useEffect, useState } from 'react';

import { checkUserPanelAccess } from 'helpers/dashboard';
import { CustomMenu, CustomToggle } from 'pages/dashboard/containers/sites/components/CustomMenu';
import { Dropdown } from 'react-bootstrap';
import { getUserPanelRole } from 'selectors';
import { PANEL_TLD_DOMAIN } from 'config';
import { userSiteActions } from 'pages/dashboard/containers/sites/actions/userSites';
import UserSite from 'pages/dashboard/containers/sites/components/userSite';
import UserSiteVerticalView from 'pages/dashboard/containers/sites/components/userSiteVertical';
import RowLoader from 'components/loaders/RowLoader';

const UserSites = (props) => {
  const {
    isLoading,
    userSites,
    selectedSites,
    dispatch,
    creatingSite,
    setCreatingSite,
    handleSetLoading,
    panel_role
  } = props;
  const [searchOrg, setSearchOrg] = useState('');
  const [isVertical, setIsVertical] = useState(false);

  const handleSearchFilter = (event) => {
    event.preventDefault();
    const org = event.target.value;
    setSearchOrg(org);
  };

  useEffect(() => {
    dispatch(userSiteActions.fetchUserSites());
  }, [dispatch]);

  const settings = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  const handleClick = (site) => {
    dispatch(
      userSiteActions.UpdateSelectedSites({
        removedSelectedSite: selectedSites.includes(site),
        data: site
      })
    );
  };

  const filterSites = userSites.filter((item) => {
    return !searchOrg || item.slug.startsWith(searchOrg.toLowerCase());
  });
  const isCreateSiteVisible = window.location.origin.includes(PANEL_TLD_DOMAIN) && checkUserPanelAccess(panel_role);

  return (
    <div>
      <div className="integration-holder sites-frame">
        <div className="sites-head">
          <h2>Your sites</h2>
          <div className="site-controls-holder">
            <div className="site-switch-controls">
              <span
                style={{ border: `${isVertical ? '1px solid #ccc' : 'none'}` }}
                onClick={(event) => {
                  event.preventDefault();
                  setIsVertical(true);
                  setSearchOrg('');
                }}
              >
                <FontAwesomeIcon icon={Icons.faList} />
              </span>
              <span
                style={{ border: `${!isVertical ? '1px solid #ccc' : 'none'}` }}
                onClick={(event) => {
                  event.preventDefault();
                  setIsVertical(false);
                  setSearchOrg('');
                }}
              >
                <FontAwesomeIcon icon={Icons.faThLarge} />
              </span>
            </div>
            {!isVertical && (
              <input
                type="text"
                value={searchOrg}
                className="ml-2 form-control small mb-0"
                onChange={handleSearchFilter}
                name="searchSite"
                placeholder="Search a site"
                required
              />
            )}
            {isCreateSiteVisible && (
              <button
                className="btn btn-primary ml-2"
                style={{
                  display: creatingSite ? 'none' : 'block'
                }}
                onClick={() => setCreatingSite(true)}
              >
                Create new site{' '}
              </button>
            )}
          </div>
        </div>
        {isLoading ? (
          <RowLoader />
        ) : !isVertical ? (
          filterSites.length !== 0 ? (
            <div className="app-block">
              <div className="sites-slider">
                <Slider {...settings}>
                  <UserSite site={{ slug: 'all' }} />
                  {filterSites.map((item, index) => {
                    return <UserSite handleSetLoading={handleSetLoading} site={item} key={index} />;
                  })}
                </Slider>
              </div>
            </div>
          ) : (
            <div>
              No site with this name:<b> {searchOrg} </b>
            </div>
          )
        ) : (
          <div className="app-block">
            <div className="sites-slider">
              <Dropdown style={{ minWidth: '100%', margin: '0' }}>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                  <div className="sites-dropdown">
                    {selectedSites.map((site, index) => {
                      return (
                        <div key={index} className="site-chips">
                          {site}{' '}
                          {site.toUpperCase() !== 'ALL' && (
                            <span
                              onClick={(event) => {
                                event.preventDefault();
                                handleClick(site);
                              }}
                              className="chips-close"
                            >
                              <FontAwesomeIcon icon={Icons.faPlus} />
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomMenu}>
                  <Dropdown.Item eventKey="all">
                    <UserSiteVerticalView site={{ slug: 'all' }} />
                  </Dropdown.Item>
                  {userSites.map((item, index) => {
                    return (
                      <Dropdown.Item eventKey={item.slug} key={index}>
                        <UserSiteVerticalView handleSetLoading={handleSetLoading} site={item} key={index} />
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const panel_role = getUserPanelRole(state);
  return {
    panel_role,
    userSites: state.USER_SITES.user_site,
    selectedSites: state.USER_SITES.selected_sites,
    isLoading: state.USER_SITES.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

const connectedUserSite = connect(mapStateToProps, mapDispatchToProps)(UserSites);
export default connectedUserSite;
