import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { analyticsSummaryActions } from 'pages/insights/containers/analyticsSummaryContainer/actions';
import { learnersAnalyticsActions } from 'pages/insights/containers/learnersAnalyticsContainer/actions';
import { coursesAnalyticsActions } from 'pages/insights/containers/coursesAnalyticsContainer/actions';
import { escapeHtml } from 'helpers';

const moment = require('moment');
const lodash = require('lodash');
const axios = require('axios');

export const configureInsightsData = (dispatch) => {
  dispatch(analyticsSummaryActions.fetchCoursesEnrollmentStats());
  dispatch(analyticsSummaryActions.fetchCoursesCompletionStats());
  dispatch(analyticsSummaryActions.fetchMonthlyUsersStats());
  dispatch(analyticsSummaryActions.fetchSiteDailyMetrics());
  dispatch(analyticsSummaryActions.fetchSiteMonthlyMetrics());
  dispatch(learnersAnalyticsActions.fetchAllUsersAnalytics());
  dispatch(coursesAnalyticsActions.fetchCoursesMAUsData());
  dispatch(coursesAnalyticsActions.fetchAllCoursesGeneralData());
};

export const downloadPagePDF = (page_name) => {
  document.getElementById('page_content').classList.add('pdf-styling');
  const page_content = document.getElementById('page_main_content');

  html2canvas(page_content, {
    allowTaint: true,
    useCORS: true,
    logging: true,
    scrollX: 0,
    scrollY: -window.scrollY
  }).then((canvas) => {
    const imgData = canvas.toDataURL('image/jpeg');
    const pdf = new jsPDF('p', 'mm', 'a4');

    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
    pdf.save(page_name);
  });
  document.getElementById('page_content').classList.remove('pdf-styling');
};

export const downloadMultiPagePDF = (page_name) => {
  document.getElementById('page_content').classList.add('pdf-styling');
  const page_content = document.getElementById('page_main_content');

  html2canvas(page_content, {
    allowTaint: true,
    useCORS: true,
    logging: true,
    scrollX: 0,
    scrollY: -window.scrollY
  }).then((canvas) => {
    const imgData = canvas.toDataURL('image/jpeg');
    const doc = new jsPDF('p', 'mm', 'a4');

    const pageHeight = doc.internal.pageSize.getHeight();
    const imgProps = doc.getImageProperties(imgData);
    const imgWidth = doc.internal.pageSize.getWidth();
    const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

    let heightLeft = imgHeight;
    let position = 0;
    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }
    doc.save(page_name);
  });
  document.getElementById('page_content').classList.remove('pdf-styling');
};

export const getCoursesEnrollmentStats = (courses_data) => {
  return courses_data.map((course) => {
    return [escapeHtml(course.course_name), course.enrollment_count];
  });
};

export const getCoursesCompletionStats = (courses_data) => {
  return courses_data.map((course) => {
    return [escapeHtml(course.course_name), course.num_learners_completed];
  });
};

export const prepareSummaryDashboardCSVData = (
  total_site_learners,
  total_site_staff_users,
  current_month_maus,
  total_active_courses,
  current_course_completions,
  courses_count,
  enrollment_results,
  completion_results
) => {
  return [
    ['Analytics Summary Report: '],
    [''],
    ['Total Learners', total_site_learners],
    ['Monthly Active Users', current_month_maus],
    ['Course completions', current_course_completions],
    ['Total Courses', courses_count],
    ['Active Courses', total_active_courses],
    ['Staff Users', total_site_staff_users],
    [''],
    ['Top Course by Enrollment'],
    [''],
    ...getCoursesEnrollmentStats(enrollment_results),
    [''],
    ['Top Course by Completions'],
    [''],
    ...getCoursesCompletionStats(completion_results)
  ];
};

export const getLearnerCompletedCourses = (courses_data) => {
  return courses_data.filter((course) => course.progress_data.course_completed === true);
};

export const getLearnerDetailInfo = (learners_data, username) => {
  return learners_data.find((learner) => learner.username === username);
};

export const prepareLearnersDashboardCSVData = (props) => {
  const {
    total_site_learners,
    all_learners_analytics,
    site_daily_metrics,
    site_monthly_metrics,
    current_learners_maus,
    previous_learners_maus,
    current_course_completions
  } = props;

  let registrationFields = [];

  if (Object.keys(all_learners_analytics).length > 0) {
    Object.keys(all_learners_analytics[0].registration_fields).map((key) =>
      registrationFields.push(lodash.startCase(key))
    );
  }

  let learners_info = [
    ['Learner Analytics Report: '],
    [''],
    ['Total Users', total_site_learners],
    ['New User Registrations (Current Month)', site_monthly_metrics.current_month.new_users],
    ['New User Registrations (Last Month)', site_monthly_metrics['last_month'].new_users],
    ['Monthly Active Users (Current Month)', current_learners_maus],
    ['Monthly Active Users (Last Month)', previous_learners_maus],
    ['Active Users Today', site_daily_metrics.todays_active_learners_count],
    ['Course Completions', current_course_completions],
    [''],
    ['Learners Overview'],
    [
      'Name',
      'Username',
      'Email',
      ...registrationFields,
      'Courses Enrolled',
      'Courses Completed',
      'Account Created',
      'Last Login',
      'Last Course Activity'
    ],
    ['']
  ];

  all_learners_analytics &&
    Object.values(all_learners_analytics).forEach((learner) => {
      const learner_registration_fields = Object.keys(learner.registration_fields).map(
        (key) => learner.registration_fields[key]
      );

      learners_info.push([
        learner.name,
        learner.username,
        learner.email,
        ...learner_registration_fields,
        learner.courses.length,
        getLearnerCompletedCourses(learner.courses).length,
        learner.date_joined ? moment(learner.date_joined).format('YYYY-M-DD') : 'N/A',
        learner.last_login ? moment(learner.date_joined).format('YYYY-M-DD HH:mm:ss') : 'N/A',
        learner.course_activity_date ? moment(learner.course_activity_date).format('YYYY-M-DD HH:mm:ss') : 'N/A'
      ]);
    });

  return learners_info;
};

export const prepareLearnerDetailInfoCSVData = (learner_info) => {
  if (learner_info === undefined) {
    return [];
  }

  const registration_fields = Object.keys(learner_info.registration_fields).map((key) => [
    lodash.startCase(key),
    learner_info.registration_fields[key]
  ]);

  let learner_data = [
    ['Learner Details Report: '],
    [''],
    ['Username', learner_info.username],
    ['Email', learner_info.email],
    ...registration_fields,
    ['Courses Enrolled', learner_info.courses.length],
    ['Courses Completed', getLearnerCompletedCourses(learner_info.courses).length],
    ['Status', learner_info.is_active === true ? 'Active' : 'False'],
    ['Account Created', learner_info.date_joined ? moment(learner_info.date_joined).format('YYYY-M-DD') : 'N/A'],
    ['Last Login', learner_info.last_login ? moment(learner_info.date_joined).format('YYYY-M-DD HH:mm:ss') : 'N/A'],
    [
      'Last Course Activity',
      learner_info.course_activity_date ? moment(learner_info.course_activity_date).format('YYYY-M-DD HH:mm:ss') : 'N/A'
    ],
    ['']
  ];
  if (learner_info.courses.length > 1) {
    learner_data.push([
      'Course Title',
      'Enrollment Date',
      'Completion Date',
      'Grade',
      'Graded Course Progress',
      'Total Course Progress'
    ]);
    let learner_courses_data = learner_info.courses.map((course) => {
      return [
        escapeHtml(course.course_name),
        course.date_enrolled,
        course.progress_data.passed_timestamp
          ? moment(course.progress_data.passed_timestamp).format('MMM DD, YYYY')
          : 'N/A',
        course.progress_data.letter_grade ? course.progress_data.letter_grade : 'N/A',
        `${course.progress_data.course_progress}%`,
        `${course.progress_data.total_progress_percent}%`
      ];
    });
    learner_data = learner_data.concat(learner_courses_data);
  }
  return learner_data;
};

export const getCourseDetailInfo = (courses_data, course_id) => {
  return courses_data.find((course) => course.course_id === course_id);
};

export const prepareCoursesAnalyticsCSVData = (all_courses_stats) => {
  let courses_data = [
    ['Courses Analytics Report: '],
    [''],

    [
      'Course Id',
      'Course Title',
      'Instructors',
      'Start Date',
      'End Date',
      'Total Enrollments',
      'Active Learners',
      'Total completions',
      'Average days to complete',
      'Completion Rate'
    ],
    ['']
  ];

  let courses_data_listing = all_courses_stats.map((course) => {
    return [
      course.course_id,
      escapeHtml(course.course_name),
      getCourseInstructors(course),
      course.start_date ? moment(course.start_date).format('MMM DD, YYYY') : '',
      course.end_date ? moment(course.end_date).format('MMM DD, YYYY') : '',
      course.metrics ? course.metrics.enrollment_count : 'N/A',
      course.metrics ? course.metrics.active_learners_today : 'N/A',
      course.metrics ? course.metrics.num_learners_completed : 'N/A',
      course.metrics ? course.metrics.average_days_to_complete : 'N/A',
      course.metrics && course.metrics.num_learners_completed > 0 && course.metrics.enrollment_count
        ? `${Math.round((course.metrics.num_learners_completed / course.metrics.enrollment_count) * 100)}%`
        : 'N/A'
    ];
  });
  return courses_data.concat(courses_data_listing);
};

export const getCourseInstructors = (course_data) => {
  let instructors = [];
  course_data.staff.forEach(function(instructor) {
    if (instructor.role === 'instructor') {
      instructors.push(instructor.username);
    }
  });

  return instructors.length > 0 ? instructors.join(', ') : 'N/A';
};

export const fetchDataRecursive = async (url, params) => {
  let requestOptions = {
    method: 'GET',
    withCredentials: true,
    url
  };

  if (params !== undefined) {
    requestOptions['params'] = params;
  }

  const response = await axios(requestOptions);
  const data = response.data.results;
  if (!response.data.next) {
    return response.data.results;
  } else {
    return data.concat(await fetchDataRecursive(response.data.next));
  }
};
