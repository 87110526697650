const NODE_ENV = process.env.NODE_ENV;
const PANEL_BASE_DOMAIN = process.env.REACT_APP_PANEL_BASE_DOMAIN;
const PANEL_BACKEND_BASE_DOMAIN = process.env.REACT_APP_PANEL_BACKEND_BASE_DOMAIN;
const LMS_BASE_URL = process.env.REACT_APP_LMS_BASE_URL;
const ECOMMERCE_BASE_URL = process.env.REACT_APP_ECOMMERCE_BASE_URL;
const HELP_BASE_URL = process.env.REACT_APP_HELP_BASE_URL;
const PANEL_BASE_URL = process.env.REACT_APP_PANEL_BASE_URL;
const PANEL_TLD_DOMAIN = process.env.REACT_APP_TLD_DOMAIN;
const HTTP_PROTOCOL = process.env.REACT_APP_HTTP_PROTOCOL;
const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const STRIPE_CUSTOMER_PORTAL = process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL;
const MIXPANEL_PROJECT_TOKEN = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN;
const HUBSPOT_CHAT_SRC = process.env.REACT_APP_HUBSPOT_CHAT_SRC;
const CLARITY_PROJECT_ID = process.env.REACT_APP_CLARITY_PROJECT_ID;
const EDLY_SAAS_PID = process.env.REACT_APP_EDLY_SAAS_PID;
const EDX_API_ENDPOINTS = process.env.REACT_APP_EDX_API_ENDPOINTS
  ? process.env.REACT_APP_EDX_API_ENDPOINTS
  : {
      MAUS_API: 'api/edly_panel/v1/monthly_active_users/',
      FETCH_USERS_INFO: 'api/edly_panel/v1/users/',
      ACCOUNTS_INFO: 'api/user/v1/accounts',
      FETCH_USERNAME: 'api/user/v1/me',
      USER_PREFERENCES: 'api/user/v1/preferences/',
      RESET_USER_PASSWORD: 'password_reset/',
      UPLOAD_USER_PROFILE_IMAGE: 'api/profile_images/v1/',
      FETCH_COUNTRY_TIMEZONES: 'user_api/v1/preferences/time_zones/',
      FETCH_CSRF_TOKEN: 'api/edly_panel/v1/csrf_token/',
      UPDATE_USERS_ACCOUNT_STATUS: 'api/edly_panel/v1/accounts/status_update/',
      FETCH_USERS_ACCOUNTS_DATA: 'api/edly_panel/v1/accounts',
      FETCH_CURRENT_USER_DETAIL: 'api/edly_panel/v1/me',
      VALIDATE_USER_INFO: 'api/user/v1/validation/registration',
      UPDATE_LMS_THEME: 'api/edly_panel/v1/site_themes/',
      FETCH_MONTHLY_ENROLLMENT: 'api/edly_panel/v1/monthly_enrollments/',
      FETCH_MONTHLY_COURSE_COMPLETIONS: 'api/edly_panel/v1/monthly_course_completions/',
      UPDATE_ECOMMERCE_THEME: 'edly_ecommerce_api/v1/site_themes/',
      FETCH_ECOMMERCE_CSRF_TOKEN: 'edly_ecommerce_api/v1/csrf_token/',
      FETCH_MONTHLY_USERS_STATS: 'api/edly_panel/v1/monthly_users_stats/',
      DOWNLOAD_LEARNERS_DETAIL_PDF: 'figures/api/users/pdf/',
      BULK_USERS_UPLOAD: 'api/edly_panel/v1/bulk_registration/',
      EDLY_SITE_CONFIG: 'api/edly_panel/v1/edly_site_config/',
      BADGE_API: 'api/edly_panel/v1/site_badges/',
      USER_SITES: 'api/v1/user_link_sites/',
      COOKIE_REFRESH: 'api/cookie/refresh/',
      LTI_CONFIGURATION: 'api/edly_panel/v1/lti_configuration/',
      DATA_BACKUP: 'api/edly_panel/v1/download_backup/'
    };
const PANEL_API_ENDPOINTS = process.env.REACT_APP_PANEL_API_ENDPOINTS
  ? process.env.REACT_APP_PANEL_API_ENDPOINTS
  : {
      NOTIFICATIONS_API: 'api/v1/notifications/',
      SERVICES_NOTIFICATIONS_API: 'api/v1/all_services_notifications/',
      THEMES_API: 'api/v1/themes/',
      ORGANIZATIONS_API: 'api/v1/organizations/',
      PRICING_API: 'api/v1/pricing/',
      STRIPE_PRODUCTS_LIST_API: 'api/v1/stripe/products',
      STRIPE_CHECKOUT_SESSION: 'api/v1/stripe/checkout/session/',
      FETCH_PANEL_CSRF_TOKEN: 'api/v1/csrf_token/',
      PAYMENT_METHOD_API: 'api/v1/payments/',
      FETCH_INVOICE_LISTING: 'api/v1/invoices/',
      EDLY_SITE_CONFIG: 'api/v1/edly_site_config/',
      CLIENT_BUSINESS: 'api/v1/client_business/',
      TRACKING_EVENTS: 'api/v1/tracking_events/',
      FREE_TRIAL: 'api/v1/trial/',
      DNS_ENTRIES: 'api/v1/dns_entries/',
      JENKINS_DNS_SECOND_JOB: 'api/v1/jenkins_dns_second_job/',
      CREATE_SITE: 'api/v1/create_site/',
      FEATURE_OVERVIEW: 'api/v1/feature_overview/',
      VERIFY_DNS_ENTRIES: 'api/v1/verify_dns_entries',
      RESET_DNS_TO_DEFAULT: 'api/v1/reset_dns/'
    };

const FIGURES_API_ENDPOINTS = process.env.REACT_APP_FIGURES_API_ENDPOINTS
  ? process.env.REACT_APP_FIGURES_API_ENDPOINTS
  : {
      COURSES_GENERAL_API: 'figures/api/courses/general/',
      GENERAL_SITE_METRICS_API: 'figures/api/general-site-metrics/',
      COURSES_STATS_API: 'figures/api/courses/stats/',
      USERS_DETAIL_API: 'figures/api/users/detail/',
      COURSE_DETAIL_API: 'figures/api/courses/detail/',
      COURSE_DETAIL_CSV_API: 'figures/api/edly/insights-courses/',
      SITE_DAILY_METRIC_API: 'figures/api/site-daily-metrics/',
      SITE_MONTHLY_METRIC_API: 'figures/api/site-monthly-metrics/',
      COURSE_ENROLLMENTS_API: 'figures/api/course-enrollments/',
      COURSES_MAUS_API: 'figures/api/course-mau-live-metrics/',
      LEARNER_DETAIL_API: 'figures/api/edly/learner-report/',
      INSIGHTS_SUMMARY_CSV: 'figures/api/edly/insights-summary/',
      LEARNERS_SUMMARY_CSV: 'figures/api/edly/insights-learner/',
      INSIGHTS_COURSES_CSV: 'figures/api/edly/insights-courses/',
      GENERAL_SITES_METRICS_API: 'figures/api/general-sites-metrics'
    };

const WORDPRESS_API_ENDPOINTS = process.env.REACT_APP_WORDPRESS_API_ENDPOINTS
  ? process.env.REACT_APP_WORDPRESS_API_ENDPOINTS
  : {
      CREATE_USER_ACCOUNT: 'wp-json/edly-wp-routes/v1/users/create/',
      UPDATE_USER_ROLE: 'wp-json/edly-wp-routes/v1/users/update-role/',
      UPDATE_THEME: 'wp-json/edly-wp-routes/v1/themes/change',
      EDLY_SITES_CONFIG: 'wp-json/edly_api_config/v1/edly_sites_config'
    };

const FETCH_SERVICES_NOTIFICATION_TIMER = process.env.REACT_APP_FETCH_SERVICES_NOTIFICATION_TIMER
  ? process.env.REACT_APP_FETCH_SERVICES_NOTIFICATION_TIMER
  : 180000; // 3 * 60 * 1000 (time in milliseconds)

const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE ? parseInt(process.env.REACT_APP_PAGE_SIZE) : 10; // Pagination Size

const EXPORT_PAGE_SIZE = process.env.REACT_APP_EXPORT_PAGE_SIZE
  ? parseInt(process.env.REACT_APP_EXPORT_PAGE_SIZE)
  : 250;

const RETIRED_EMAIL_PREFIX = process.env.REACT_APP_RETIRED_EMAIL_PREFIX
  ? process.env.REACT_APP_RETIRED_EMAIL_PREFIX
  : 'retired__user_';

const PASSWORD_RESET_EMAIL_TIME_LIMIT = process.env.REACT_APP_PASSWORD_RESET_EMAIL_TIME_LIMIT
  ? process.env.REACT_APP_PASSWORD_RESET_EMAIL_TIME_LIMIT
  : 60; // time in seconds.

export {
  NODE_ENV,
  PANEL_BASE_DOMAIN,
  PANEL_BACKEND_BASE_DOMAIN,
  LMS_BASE_URL,
  PAGE_SIZE,
  ECOMMERCE_BASE_URL,
  HELP_BASE_URL,
  PANEL_BASE_URL,
  HTTP_PROTOCOL,
  EDX_API_ENDPOINTS,
  PANEL_API_ENDPOINTS,
  FIGURES_API_ENDPOINTS,
  WORDPRESS_API_ENDPOINTS,
  FETCH_SERVICES_NOTIFICATION_TIMER,
  RETIRED_EMAIL_PREFIX,
  EXPORT_PAGE_SIZE,
  STRIPE_PUBLIC_KEY,
  STRIPE_CUSTOMER_PORTAL,
  PASSWORD_RESET_EMAIL_TIME_LIMIT,
  MIXPANEL_PROJECT_TOKEN,
  HUBSPOT_CHAT_SRC,
  CLARITY_PROJECT_ID,
  PANEL_TLD_DOMAIN,
  EDLY_SAAS_PID
};
