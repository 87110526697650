import React from 'react';
import { Link } from 'react-router-dom';

import { escapeHtml, getCourseInstructors } from 'helpers';

const moment = require('moment');

const CourseListItem = (props) => {
  const { course } = props;
  return (
    <div className="course-details" key={course.course_id}>
      <div className="course-meta">
        <span className="course-code">{course.course_code}</span>
        <Link
          to={{ pathname: `/panel/insights/courses/course-details/${course.course_id}` }}
          className="course-name"
          key={course.course_id}
        >
          {escapeHtml(course.course_name)}
        </Link>
        <span className="course-dated">
          <span className="text-muted">Dated: </span>
          <span>
            {course.start_date ? moment(course.start_date).format('MMM DD, YYYY') : ''} &mdash;{' '}
            {course.end_date ? moment(course.end_date).format('MMM DD, YYYY') : 'N/A'}
          </span>
        </span>
        <span className="course-instructors">
          <span className="text-muted">Instructors: </span>
          <span>{getCourseInstructors(course)}</span>
        </span>
      </div>

      <div className="course-analytics-values">
        <div>
          <span className="text-muted">
            Total <br /> Enrollments
          </span>
          {course.metrics ? course.metrics.enrollment_count : 'N/A'}
        </div>
        <div>
          <span className="text-muted">
            Active <br /> Learners
          </span>
          {course.metrics ? course.metrics.active_learners_this_month : 'N/A'}
        </div>
        <div>
          <span className="text-muted">
            Total <br /> completions
          </span>
          {course.metrics ? course.metrics.num_learners_completed : 'N/A'}
        </div>
        <div>
          <span className="text-muted">
            Average days <br /> to complete
          </span>
          {course.metrics ? course.metrics.average_days_to_complete : 'N/A'}
        </div>
        <div>
          <span className="text-muted">
            Completion <br /> Rate
          </span>
          {course.metrics && course.metrics.num_learners_completed > 0 && course.metrics.enrollment_count > 0
            ? `${Math.round((course.metrics.num_learners_completed / course.metrics.enrollment_count) * 100)}%`
            : 'N/A'}
        </div>
      </div>
    </div>
  );
};

export default CourseListItem;
