import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import mixpanel from 'mixpanel-browser';

import Header from 'components/header';
import Footer from 'components/footer';
import SideBar from 'components/sidebar';
import { getOrganization, getUserSettings, getUserLmsRole, getUserPanelRole } from 'selectors';
import CTABanner from 'components/ctaBanner';

const ProtectedLayout = ({ children, ...props }) => {
  mixpanel.identify(props.email);
  mixpanel.people.set_once({
    $email: props.email,
    $name: props.username,
    department: props.lms_role
  });

  const { username, profile_image, panel_role, organization } = props;
  const handleLogout = (e) => {
    e.preventDefault();
    mixpanel.reset();
    localStorage.removeItem('edx_username');
    localStorage.removeItem('lms_csrf_token');
    localStorage.removeItem('panel_csrf_token');
    localStorage.removeItem('wordpress_base_url');
    localStorage.removeItem('ecommerce_base_url');
    window.location.href = e.currentTarget.getAttribute('href');
  };

  return (
    <Fragment>
      <a className="skip-link screen-reader-text" href="#content">
        Skip to content
      </a>
      <Header
        username={username}
        profile_image={profile_image}
        panel_role={panel_role}
        organization={organization}
        handleLogout={handleLogout}
      />
      {organization?.current_site_plan === 'trial' && <CTABanner />}
      <main
        className={
          window.location.pathname === '/panel/see_all_apps' || window.location.pathname === '/panel/notifications'
            ? 'main see-all-page'
            : 'main'
        }
        id="content"
      >
        <div id="page_container" className="container">
          <div className="sidebar">
            <SideBar />
          </div>
          <div className="main-content" id="page_main_content">
            {children}
          </div>
        </div>
      </main>
      <Footer />
    </Fragment>
  );
};

function mapStateToProps(state) {
  const organization = getOrganization(state);
  const { username, profile_image, email } = getUserSettings(state);
  const panel_role = getUserPanelRole(state);
  const lms_role = getUserLmsRole(state);
  return {
    username,
    panel_role,
    profile_image,
    organization,
    email,
    lms_role
  };
}

export default connect(mapStateToProps)(ProtectedLayout);
