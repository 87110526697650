import React from 'react';

import { escapeHtml } from 'helpers';
const moment = require('moment');

const LearnerCourseListItem = (props) => {
  const breakCourseID = (slug) => {
    if (slug.length > 30) {
      const [slug1, slug2, slug3] = slug.split('+');
      const lineBreak = slug2.length + slug3.length < 30 ? '' : '\n';
      return `${slug1}+\n${slug2}+${lineBreak}${slug3}`;
    }
    return slug;
  };
  const { course } = props;
  return (
    <tr>
      <td data-title="Course Course Title">{escapeHtml(course.course_name)}</td>
      <td align="center" data-title="Course Key">
        {breakCourseID(course.course_id)}
      </td>
      <td align="center" data-title="Enrollment Date">
        {course.date_enrolled}
      </td>
      <td align="center" data-title="Enrollment Status">
        {course.is_active === true ? 'Active' : 'In-active'}
      </td>
      <td align="center" data-title="Completion Date">
        {course.progress_data.passed_timestamp
          ? moment(course.progress_data.passed_timestamp).format('YYYY-M-DD')
          : 'N/A'}
      </td>
      <td align="center" data-title="Grade">
        {course.progress_data.letter_grade ? course.progress_data.letter_grade : 'N/A'}
      </td>
      <td align="center" data-title="Graded Course Progress">
        {course.progress_data.course_progress}%
      </td>
      <td align="center" data-title="Total Course Progress">
        {course.progress_data.total_progress_percent}%
      </td>
    </tr>
  );
};

export default LearnerCourseListItem;
