import { userSiteService } from 'services';
import { messageNotification, validateLoginSession } from 'helpers';
import { userSite } from 'pages/dashboard/containers/sites/constants';

const fetchUserSites = () => {
  return async (dispatch) => {
    await dispatch(userSite.requestUserSiteloading());
    userSiteService
      .fetchUserSites()
      .then((siteData) => {
        Promise.resolve(dispatch(userSite.requestUserSiteSuccess(siteData)));
      })
      .catch((error) => {
        validateLoginSession(dispatch, error);
        dispatch(userSite.requestUserSiteFailure(error));
        messageNotification('danger', 'There was an error fetching user sites', error.response.data.error);
      });
  };
};

const UpdateSelectedSites = (payload) => {
  return (dispatch) => {
    Promise.resolve(dispatch(userSite.UpdateSelectedSites(payload)));
  };
};

const setUserCurrentSites = (payload) => {
  return (dispatch) => {
    Promise.resolve(dispatch(userSite.setUserCurrentSite(payload)));
  };
};

export const userSiteActions = {
  fetchUserSites,
  UpdateSelectedSites,
  setUserCurrentSites
};
