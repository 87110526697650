import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';

import { messageNotification, getWordpressBaseUrl } from 'helpers';
import { usersActions } from 'pages/users/containers/usersContainer/actions';
import PanelRolesButton from 'pages/users/containers/usersContainer/components/panelRolesButton';
import { EDIT_USER_FIELD } from 'pages/users/containers/usersContainer/constants';

const EditUser = (props) => {
  const { validation_messages, page, searchText, roles, password_reset, pageSize } = props;
  const {
    id,
    username,
    name,
    email,
    is_staff,
    is_active,
    is_blocked,
    is_superuser,
    lms_role,
    wordpress_role,
    panel_role,
    is_social_user
  } = props.user;
  const [inputs, setInputs] = useState({
    username: '',
    name: '',
    email: '',
    is_staff: true,
    is_active: true,
    is_blocked: false,
    is_superuser: false,
    lms_role: 'learner',
    wordpress_role: 'subscriber',
    panel_role: 'panel_restricted'
  });
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    setInputs({
      id: id,
      username: username,
      name: name,
      email: email,
      is_staff: is_staff,
      is_active: is_active,
      is_blocked: is_blocked,
      is_superuser: is_superuser,
      lms_role: lms_role || 'learner',
      wordpress_role: wordpress_role || 'subscriber',
      panel_role: panel_role || 'panel_restricted'
    });
  }, [id, username, name, email, is_staff, is_active, is_blocked, is_superuser, lms_role, wordpress_role, panel_role]);

  const handleValidation = (event) => {
    event.persist();
    props.dispatch(usersActions.validateUser({ name: inputs.name }));
    if (inputs.name) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const handleInputValueChange = (event) => {
    event.persist();
    if (event.target.name === 'is_active_user') {
      setInputs((inputs) => ({ ...inputs, is_active: !inputs.is_active }));
    } else if (event.target.name === 'is_blocked_user') {
      setInputs((inputs) => ({ ...inputs, is_blocked: event.target.checked }));
    } else {
      setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }));
    }
    if (inputs.name) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const handleSubmit = (event) => {
    setIsRequestInProgress(true);
    event.preventDefault();
    let payload = inputs;
    payload['page_number'] = page;
    payload['searchText'] = searchText;
    payload['roles'] = roles;
    payload['page_size'] = pageSize;
    props.dispatch(
      usersActions.validateUser(
        { name: inputs.name },
        usersActions.updateUser(payload, props.onHide, setIsRequestInProgress, props.admin_email)
      )
    );
    if (inputs.name) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const shouldButtonBeDisabled = () => {
    const isUserUpdated = EDIT_USER_FIELD.find((item) => props.user[item] !== inputs[item]);
    if (isRequestInProgress) {
      return true;
    } else {
      return Boolean(!isUserUpdated) || buttonDisabled;
    }
  };

  const handlePasswordReset = (email) => {
    if (email) {
      props.dispatch(usersActions.resetUserPassword(email));
    } else {
      messageNotification(
        'danger',
        'There was an error resetting user password',
        'Please enter email address to reset password'
      );
    }
  };

  return (
    <div>
      <Modal onHide={props.onHide} show={props.show} size={props.size}>
        <Modal.Header>
          <Modal.Title>
            <span className="close" onClick={props.onHide}>
              <FontAwesomeIcon icon={Icons.faTimes} />
            </span>
            <strong className="title">Editing user: </strong>
            <h2>{inputs.username}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="email">
              Email<span className="required">*</span>
            </label>
            <input
              type="text"
              id="email"
              name="email"
              className="form-control"
              onBlur={handleValidation}
              onChange={handleInputValueChange}
              value={inputs.email || ''}
              readOnly
            />
            <span className="text-error">{validation_messages['email']}</span>
          </div>
          <div className="form-group">
            <label htmlFor="username">
              Username
              <span className="required">*</span>
            </label>
            <input
              type="text"
              id="username"
              name="username"
              className="form-control"
              onBlur={handleValidation}
              onChange={handleInputValueChange}
              value={inputs.username || ''}
              readOnly
            />
            <span className="text-error">{validation_messages['username']}</span>
          </div>
          <div className="form-group">
            <label htmlFor="name">
              Full Name<span className="required">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="form-control"
              onBlur={handleValidation}
              onChange={handleInputValueChange}
              value={inputs.name || ''}
              readOnly
            />
            <span className="text-error">{validation_messages['name']}</span>
          </div>
          {!is_social_user && (
            <div className="form-group">
              <span
                className={password_reset ? 'btn btn-primary' : 'btn btn-primary password-reset-disabled'}
                onClick={() => handlePasswordReset(inputs.email)}
              >
                Change Password
              </span>
            </div>
          )}
          <div className="form-group">
            <div className="custom-control custom-checkbox status-input">
              <input
                type="checkbox"
                className="custom-control-input"
                name="is_active_user"
                id="is_active_user"
                value={inputs.is_active}
                onChange={handleInputValueChange}
                checked={inputs.is_active ? inputs.is_active : false}
              />
              <label className="custom-control-label" htmlFor="is_active_user">
                Active
              </label>
            </div>
            <div className="custom-control custom-checkbox status-input">
              <input
                type="checkbox"
                className="custom-control-input"
                name="is_blocked_user"
                id="is_blocked_user"
                value={inputs.is_blocked}
                onChange={handleInputValueChange}
                checked={inputs.is_blocked ? inputs.is_blocked : false}
              />
              <label className="custom-control-label" htmlFor="is_blocked_user">
                Blocked
              </label>
            </div>
          </div>
          <PanelRolesButton inputs={inputs} handleInputChange={handleInputValueChange} />
          {getWordpressBaseUrl() && (
            <div className="form-group">
              <label htmlFor="admin">Discovery Role</label>
              <div className="roles-row">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    name="wordpress_role"
                    className="custom-control-input"
                    id="subscriber_filter"
                    onChange={handleInputValueChange}
                    checked={inputs.wordpress_role.toString() === 'subscriber'}
                    value="subscriber"
                  />
                  <label className="custom-control-label" htmlFor="subscriber_filter">
                    Subscriber
                    <span className="tool-tip">
                      <FontAwesomeIcon icon={Icons.faQuestionCircle} />
                      <span className="tip-content">
                        <span className="tip-content-item">
                          User is registered on the Discovery website but does not have WordPress Admin access
                        </span>
                        <span className="tip-content-item">User cannot make any changes to the WordPress website</span>
                      </span>
                    </span>
                  </label>
                </div>
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    name="wordpress_role"
                    className="custom-control-input"
                    id="edly_admin_filter"
                    onChange={handleInputValueChange}
                    checked={inputs.wordpress_role.toString() === 'edly_admin'}
                    value="edly_admin"
                  />
                  <label
                    className={`custom-control-label ${inputs.is_active ? '' : 'disabled'}`}
                    htmlFor="edly_admin_filter"
                  >
                    Edly Admin
                    <span className="tool-tip">
                      <FontAwesomeIcon icon={Icons.faQuestionCircle} />
                      <span className="tip-content">
                        <span className="tip-content-item">User has full admin access to the Discovery website</span>
                        <span className="tip-content-item">
                          User can customize the WordPress website, add/delete pages and has editing access to the
                          WordPress Administration panel
                        </span>
                      </span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="admin">LMS Role</label>
            <div className="roles-row">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  name="lms_role"
                  className="custom-control-input"
                  id="learner_filter"
                  onChange={handleInputValueChange}
                  checked={inputs.lms_role.toString() === 'learner'}
                  value="learner"
                />
                <label className="custom-control-label" htmlFor="learner_filter">
                  Learner
                  <span className="tool-tip">
                    <FontAwesomeIcon icon={Icons.faQuestionCircle} />
                    <span className="tip-content">
                      <span className="tip-content-item">User cannot create a course or library</span>
                      <span className="tip-content-item">User cannot see/edit any course</span>
                      <span className="tip-content-item">User can request to become a site creator</span>
                      <span className="tip-content-item">User does not have access to Studio</span>
                    </span>
                  </span>
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  name="lms_role"
                  className="custom-control-input"
                  id="staff_filter"
                  onChange={handleInputValueChange}
                  checked={inputs.lms_role.toString() === 'staff'}
                  value="staff"
                />
                <label className={`custom-control-label ${inputs.is_active ? '' : 'disabled'}`} htmlFor="staff_filter">
                  Staff
                  <span className="tool-tip">
                    <FontAwesomeIcon icon={Icons.faQuestionCircle} />
                    <span className="tip-content">
                      <span className="tip-content-item">User can create a course or library</span>
                      <span className="tip-content-item">
                        User can view/edit/archive/publish all courses on the current site even if he/she is not a part
                        of the course team
                      </span>
                      <span className="tip-content-item">User can add/remove team members to a course team</span>
                    </span>
                  </span>
                </label>
              </div>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  name="lms_role"
                  className="custom-control-input"
                  id="course_creator_filter"
                  onChange={handleInputValueChange}
                  checked={inputs.lms_role.toString() === 'course_creator'}
                  value="course_creator"
                />
                <label
                  className={`custom-control-label ${inputs.is_active ? '' : 'disabled'}`}
                  htmlFor="course_creator_filter"
                >
                  Course Creator
                  <span className="tool-tip">
                    <FontAwesomeIcon icon={Icons.faQuestionCircle} />
                    <span className="tip-content">
                      <span className="tip-content-item">User can create a course or library</span>
                      <span className="tip-content-item">User can view/edit all courses created by them</span>
                      <span className="tip-content-item">
                        User can view/edit courses created by other users if they are a part of the course team
                      </span>
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            Cancel
          </Button>
          <Button disabled={shouldButtonBeDisabled()} variant="primary" onClick={handleSubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditUser;
