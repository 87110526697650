import React from 'react';

import 'pages/users/containers/index.scss';

const RowLoader = () => {
  return (
    <div className="section-loader loading center-align">
      <ul className="loading-dots">
        <li />
        <li />
        <li />
      </ul>
    </div>
  );
};

export default RowLoader;
