import React, { useState } from 'react';

import { Form } from 'react-bootstrap';

export const CustomMenu = React.forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
  const [value, setValue] = useState('');
  const filterItem = React.Children.toArray(children).filter(
    (child) => !value || child?.props?.eventKey?.startsWith(value)
  );

  return (
    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
      <Form.Control
        autoFocus
        className="mx-3 my-2 w-auto"
        placeholder="Search a site"
        onChange={(e) => setValue(e.target.value)}
        value={value}
      />
      <ul className="list-unstyled">
        {filterItem.length ? (
          filterItem
        ) : (
          <div>
            No site with this name:<b> {value} </b>
          </div>
        )}
      </ul>
    </div>
  );
});

export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    style={{ display: 'flex', minWidth: '40px', width: '100%', flex: 1 }}
  >
    {children}
    <span>&#x25bc;</span>
  </div>
));
