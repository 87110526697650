export * from 'selectors/quickStats';
export * from 'selectors/insights/summary';
export * from 'selectors/insights/learners';

export const getUserSettings = (state) => state.USER_SETTINGS;
export const getSiteNotifications = (state) => state.SITE_NOTIFICATIONS;
export const getSiteUsers = (state) => state.USERS;
export const getCoursesGeneralInfo = (state) => state.COURSES_GENERAL_INFO;
export const getCurrentViewedCourseDetails = (state) => state.CURRENT_COURSE_DETAILS;
export const getAllCoursesGeneralData = (state) => state.ALL_COURSES_GENERAL_DATA;
export const getAllLearnersAnalytics = (state) => state.ALL_LEARNERS_ANALYTICS;
export const getLearnerUsersInfo = (state) => state.LEARNER_USERS_INFO;
export const getSiteMetricsInfo = (state) => state.SITE_METRICS_INFO;

export const getOrganization = (state) => state.ORGANIZATION.organization;
export const getUserPanelRole = (state) => state.USER_ROLES.roles.panel_role;
export const getUserLmsRole = (state) => state.USER_ROLES.roles.lms_role;
export const getUserCurrentSite = (state) => state.USER_SITES.current_site;
export const getCoursesCompletionInfo = (state) => state.COURSES_COMPLETION_INFO.completion_results;
export const getCoursesEnrollmentInfo = (state) => state.COURSES_ENROLLMENT_INFO.enrollment_results;
export const getTotalCoursesCount = (state) => state.COURSES_GENERAL_INFO.course_count;
export const getCurrentViewedLearnerDetails = (state) => state.LEARNER_DETAILS.learner_info;

export const getCurrentTotalActiveCourses = (state) => state.GENERAL_SITE_METRICS.total_active_courses.current_month;
export const getCurrentTotalActiveCoursesTotalCount = (state) =>
  state.GENERAL_SITE_METRICS.total_active_courses.total_count;
export const getCurrentTotalActiveCoursesPercentageChange = (state) =>
  state.GENERAL_SITE_METRICS.total_active_courses.percentage_change;

export const getCurrentTotalSiteLearners = (state) => state.GENERAL_SITE_METRICS.total_site_learners.current_month;
export const getCurrentTotalSiteLearnersTotalCount = (state) =>
  state.GENERAL_SITE_METRICS.total_site_learners.total_count;
export const getCurrentTotalSiteLearnersPercentageChange = (state) =>
  state.GENERAL_SITE_METRICS.total_site_learners.percentage_change;

export const getCurrentSiteLearnerHistory = (state) => state.GENERAL_SITE_METRICS.total_site_learners.history;

export const getCurrentTotalSiteCourses = (state) => state.GENERAL_SITE_METRICS.total_site_courses.current_month;
export const getCurrentTotalSiteCoursesTotalCount = (state) =>
  state.GENERAL_SITE_METRICS.total_site_courses.total_count;
export const getCurrentTotalSiteCoursesPercentageChange = (state) =>
  state.GENERAL_SITE_METRICS.total_site_courses.percentage_change;

export const getCurrentTotalSiteStaffUsers = (state) => state.GENERAL_SITE_METRICS.total_site_staff_users.current_month;
export const getCurrentTotalSiteStaffUsersTotalCount = (state) =>
  state.GENERAL_SITE_METRICS.total_site_staff_users.total_count;
export const getCurrentTotalSiteStaffUsersPercentageChange = (state) =>
  state.GENERAL_SITE_METRICS.total_site_staff_users.percentage_change;

export const getUserSites = (state) => state.USER_SITES;
